import React, {useContext, useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import WatchlistTableHeader from './WatchlistTableHeader';
import {stableSort} from '../../Helpers/tableHelpers';
import WatchlistRow from './WatchlistRow';
import AppContext from '../../Context/AppContext';

const WatchlistTable = ({
  tableData,
  page,
  dataTypes,
  orderBy,
  order,
  rowsPerPage,
  allowedChart,
  allowedWatchlist,
  optionsColumns,
  lockedColumns,
  optionsAllowed,
  useAI,
}) => {
  const [orderByState, setOrderBy] = useState(orderBy ?? 'Symbol');
  const [orderState, setOrder] = useState(order ?? 'asc');
  const [rowsPerPageState, setRowsPerPage] = useState(rowsPerPage ?? 100);
  const [pageState, setPage] = useState(page ?? 1);
  const [filterType, setFilterType] = useState(dataTypes?.find((type) => type.key === orderByState) ?? {});
  const {activeWatchlistSymbols} = useContext(AppContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    if (orderByState === property) {
      setOrder(orderState === 'asc' ? 'desc' : 'asc');
      return;
    }
    setOrderBy(property);
    setOrder(property === 'Symbol' ? 'asc' : 'desc');
    // const isAsc = orderByState === property && orderState === 'asc';
    // console.log('isAsc', isAsc, orderByState, property, orderState);
    // const newOrder = isAsc ? 'desc' : 'asc';
    // setOrderBy(property);
    // setOrder(newOrder);
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
  };

  // Avoid a layout jump when reaching the last page with empty tableData.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData?.length) : 0;

  useEffect(() => {
    setFilterType(dataTypes?.find((type) => type.key === orderByState) ?? {});
  }, [dataTypes, orderByState]);

  return (
    <Box sx={{width: 'auto', mx: 'auto'}}>
      <Paper sx={{width: 'auto', mb: 2}}>
        <TableContainer sx={{maxHeight: '80vh'}}>
          <Table stickyHeader sx={{minWidth: 0}} aria-labelledby="tableTitle" size="small">
            {dataTypes.length > 0 && (
              <WatchlistTableHeader
                order={orderState}
                orderBy={orderByState}
                onRequestSort={handleRequestSort}
                tableHeaders={dataTypes}
              />
            )}
            {tableData && tableData.length ? (
              <TableBody>
                {stableSort(tableData, orderState, orderByState, filterType, [])
                  .filter((item) => item !== null && item && Object.keys(item ?? {}).length && item?.Symbol?.value)
                  .map((row, index) => (
                    <WatchlistRow
                      key={`${row?.Symbol}-${index * 3}`}
                      row={row}
                      dataTypes={dataTypes}
                      allowedChart={allowedChart}
                      allowedWatchlist={allowedWatchlist}
                      activeWatchlistSymbols={activeWatchlistSymbols}
                      order={orderState}
                      orderBy={orderByState}
                      optionsColumns={optionsColumns}
                      lockedColumns={lockedColumns}
                      optionsAllowed={optionsAllowed}
                      useAI={useAI}
                    />
                  ))
                  .slice(pageState * rowsPerPageState, pageState * rowsPerPageState + rowsPerPageState)}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <h1 className="no-results">
                      {!tableData?.length ? 'No Items Saved To Watchlist' : 'No Items Saved To Watchlist'}
                    </h1>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={tableData?.length ?? 0}
          rowsPerPage={rowsPerPageState}
          page={pageState}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </Box>
  );
};

// export default React.memo(WatchlistTable, (prev, next) => {
//   const dataSame = isEqual(prev?.tableData, next?.tableData);
//   const orderBySame = prev?.orderBy === next?.orderBy;
//   const orderSame = prev?.order === next?.order;
//   const pageSame = prev?.page === next?.page;
//   const rowsPerPageSame = prev?.rowsPerPage === next?.rowsPerPage;
//   return dataSame && orderBySame && orderSame && pageSame && rowsPerPageSame && watchlistSame;
// });
export default WatchlistTable;
