import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Modal from 'react-modal';
import {useParams, useOutletContext} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle, faLock} from '@fortawesome/free-solid-svg-icons';
import {isEqual} from 'lodash';
import Scanner from '../Components/Scanner/Scanner';
import ScannerHeader from '../Components/Header/ScannerHeader';
import TradingViewChart from '../Components/Chart/TradingViewChart';
import OptionView from '../Components/PopOut/OptionView';
import AIView from '../Components/PopOut/AIView';
import Watchlist from '../Components/Watchlist/Watchlist';
import AppContext from '../Context/AppContext';
import {setupGroups, join} from '../Helpers/formatData';
import ETFView from '../Components/ETFView/ETFView';
import {
  customStyles,
  customStylesOptions,
  customStylesLocked,
  customStylesUpcoming,
  customStylesAI,
} from './Styles/PopupStyles';
import {ReactComponent as SectorLogo} from '../Icons/SectorLogo.svg';

const TabView = ({
  groupToChart,
  optionToView,
  symbolToChart,
  modalIsOpen,
  showOptionsModal,
  showLockedModal,
  showUpcomingModal,
  groupCollection,
  groups,
  currentDate,
  inView,
}) => {
  const [state, dispatch, wsSocket] = useOutletContext();
  const {scannersData, loadingGroups} = state;
  const {
    activeWatchlist,
    theme,
    updatingDate,
    openUpcomingModal,
    closeModal,
    closeOptionsModal,
    closeLockedModal,
    closeUpcomingModal,
    openLockedModal,
    closeAIModal,
    showAIModal,
    symbolForAI,
    activeWatchlistSymbols,
  } = useContext(AppContext);

  if (!groupCollection || !Object.keys(groupCollection || {}).length || !groups?.length || loadingGroups) return null;
  const params = useParams();
  const [scannerTitle, setScannerTitle] = useState('');
  const [anyAllowedWatchlist, setAnyAllowedWatchlist] = useState(false);
  const [groupsWithWatchlist, setGroupsWithWatchlist] = useState(groups);
  const [currentTab, setCurrentTab] = useState(0);
  const [allGroups, setAllGroups] = useState([]);
  const [loadingTab, setLoadingTab] = useState(true);
  const [groupInView, setGroupInView] = useState(null);
  const [scannerConfig, setScannerConfig] = useState({});
  const [etfViewConfig, setEtfViewConfig] = useState({});

  useEffect(() => {
    const scannerId = params?.id;
    const scannerData = scannersData?.find((s) => s.slug === scannerId);
    if (Object.keys(scannerData?.SectorStrike ?? {}).length) {
      setEtfViewConfig(scannerData?.SectorStrike);
    }
    setScannerConfig(scannerData);
    if (scannerData?.groups?.length) {
      const setGroups = setupGroups(scannerId, scannersData);
      setAllGroups(setGroups);
    }
    if (scannerData?.title === scannerTitle) return;
    setScannerTitle(scannerData?.title ?? '');
  }, [params, scannersData]);

  useEffect(() => {
    if (!groupsWithWatchlist?.length) return;
    const currentGroup = groupsWithWatchlist?.[currentTab];
    if (!currentGroup) return;
    setGroupInView(currentGroup);
  }, [groupsWithWatchlist, currentTab]);

  const leaveGroups = useCallback(
    (currentGroup, watchlistGroups, newGroup) =>
      new Promise((resolve) => {
        if (currentGroup && Object.keys(currentGroup ?? {})?.length) {
          let leavingGroup = currentGroup;
          let toLeave = [leavingGroup?.group];

          if (currentGroup?.type === 'activeWatchlist') {
            leavingGroup = allGroups.find((g) => g.group === scannerConfig?.defaultGroup);
            if (watchlistGroups?.length) {
              toLeave = [...watchlistGroups].filter((g) => g !== newGroup?.group);
            } else {
              toLeave = [leavingGroup?.group];
            }
          } else if (newGroup?.type === 'activeWatchlist') {
            if (watchlistGroups?.length && watchlistGroups?.find((g) => g !== leavingGroup?.group)) {
              toLeave = [];
            }
          } else if (currentGroup?.type === 'etf-view') {
            const etfGroups = etfViewConfig.groups.map((gr) => gr?.group);

            toLeave = [...etfGroups];
          } else {
            toLeave = [leavingGroup?.group];
          }
          // console.log(`Leaving ${toLeave.join(', ')}`);
          const {group, mainReport} = leavingGroup?.report ?? {group: null, mainReport: null};
          if (group) {
            toLeave.push(group);
          }
          if (mainReport) {
            toLeave.push(mainReport);
          }
          // setGroupsLeft(toLeave);
          if (toLeave?.length) {
            const formattedToLeave = allGroups.filter((g) => toLeave.includes(g.group));
            formattedToLeave.forEach((g) => {
              const foundInGroups = groups.find((gr) => gr.group === g?.group);
              if (foundInGroups?.joined) {
                wsSocket?.emit('group:leave', {group: `${decodeURIComponent(foundInGroups?.group)}`});
              }
            });
          }
          resolve(`Leaving ${toLeave.join(', ')}`);
        }
        resolve('No Groups to Leave');
      }),
    [scannerConfig, allGroups, groups, wsSocket, etfViewConfig],
  );

  const joinGroups = useCallback(
    (newGroup, watchlistGroups, currentGroup, today) =>
      new Promise((resolve) => {
        if (newGroup && Object.keys(newGroup ?? {})?.length) {
          let joiningGroup = newGroup;
          let toJoin = [joiningGroup?.group];

          if (newGroup?.type === 'activeWatchlist') {
            joiningGroup = scannerConfig?.allowCustomInputWatchlist?.watchlistDB
              ? allGroups.find((g) => g.group === scannerConfig?.allowCustomInputWatchlist?.watchlistDB)
              : allGroups.find((g) => g.group === scannerConfig?.defaultGroup);
            if (watchlistGroups?.length && !scannerConfig?.allowCustomInputWatchlist?.watchlistDB) {
              toJoin = [...watchlistGroups].filter((g) => g !== currentGroup?.group);
            } else {
              toJoin = [joiningGroup?.group];
            }
          } else if (newGroup?.type === 'etf-view') {
            const etfGroups = etfViewConfig.groups.map((gr) => gr?.group);
            toJoin = [...etfGroups];
          } else if (currentGroup?.type === 'activeWatchlist') {
            if (
              watchlistGroups?.length &&
              watchlistGroups?.find((g) => g === joiningGroup?.group) &&
              !scannerConfig?.allowCustomInputWatchlist?.watchlistDB
            ) {
              toJoin = [];
            }
          } else {
            toJoin = [joiningGroup?.group];
          }
          // console.log(`Joining ${toJoin.join(', ')}`);
          const {group: newReportGroup, mainReport: newGroupMainReport} = joiningGroup?.report ?? {
            group: null,
            mainReport: null,
          };

          if (newReportGroup) {
            toJoin.push(newReportGroup);
          }
          if (newGroupMainReport) {
            toJoin.push(newGroupMainReport);
          }
          // setJoinedGroups(toJoin);
          if (toJoin?.length) {
            const formattedToJoin = allGroups.filter((g) => toJoin.includes(g.group));
            const joinedGroups = formattedToJoin.map((g) => {
              const {group: rbGroup, type: rbType, date} = g;

              const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';

              const groupToJoin = {
                group: `${decodeURIComponent(rbGroup)}`,
              };

              if (rbGroup === scannerConfig?.allowCustomInputWatchlist?.watchlistDB) {
                groupToJoin.watchlistSymbols = activeWatchlistSymbols ?? [];
              }

              if (date) {
                groupToJoin.date = date ?? today;
              }
              if (currentDate) {
                groupToJoin.date = currentDate;
              }
              wsSocket?.emit(`${scannerType}:join`, groupToJoin);
              return {...g, sentJoin: true};
            });
            dispatch({type: 'EMIT_JOIN', payload: {joinedGroups}});
          }
          resolve(`Joining ${toJoin.join(', ')}`);
        }
        resolve('No Groups to Join');
      }),
    [scannerConfig, allGroups, groups, wsSocket, activeWatchlistSymbols, etfViewConfig],
  );

  useEffect(() => {
    if (activeWatchlistSymbols?.length && scannerConfig?.allowCustomInputWatchlist?.watchlistDB) {
      const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
      const today = join(new Date(), acc, '-');
      wsSocket?.emit('group:leave', {
        group: `${decodeURIComponent(scannerConfig?.allowCustomInputWatchlist?.watchlistDB)}`,
      });
      const formattedToJoin = allGroups.filter(
        (g) => g.group === scannerConfig?.allowCustomInputWatchlist?.watchlistDB,
      );
      const joinedGroups = formattedToJoin.map((g) => {
        const {group: rbGroup, type: rbType, date} = g;

        const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';

        const groupToJoin = {
          group: `${decodeURIComponent(rbGroup)}`,
        };

        if (rbGroup === scannerConfig?.allowCustomInputWatchlist?.watchlistDB) {
          groupToJoin.watchlistSymbols = activeWatchlistSymbols ?? [];
        }

        if (date) {
          groupToJoin.date = date ?? today;
        }
        if (currentDate) {
          groupToJoin.date = currentDate;
        }
        wsSocket?.emit(`${scannerType}:join`, groupToJoin);
        return {...g, sentJoin: true};
      });
      dispatch({type: 'EMIT_JOIN', payload: {joinedGroups}});
    }
  }, [activeWatchlistSymbols]);

  useEffect(() => {
    if (currentTab !== inView && groupsWithWatchlist?.length) {
      const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
      const today = join(new Date(), acc, '-');

      const currentGroup = groupsWithWatchlist?.[currentTab] ?? null;
      const newGroup = groupsWithWatchlist?.[inView] ?? null;

      const watchlistGroups = Object.keys(activeWatchlist ?? {}).filter((k) => allGroups.find((g) => g.group === k));
      /**
       * Logic for leaving and joining groups.
       * If leaving watchlist, leave groups other than the one being viewed.
       * If joining watchlist, leave all groups except the ones that contain a watchlist symbol.
       * Otherwise, leave the current scanner tab and join the selected tab.
       */
      leaveGroups(currentGroup, watchlistGroups, newGroup).then(() => {
        joinGroups(newGroup, watchlistGroups, scannerConfig, today).then(() => {
          setCurrentTab(inView);
        });
      });
    }
  }, [inView]);

  const checkIfLoading = () => {
    if (!loadingTab) {
      return;
    }
    if (loadingTab) {
      const currentGroup = groupsWithWatchlist?.[currentTab];
      if (!currentGroup) return;
      let hasData = groupCollection?.[currentGroup?.group]?.joined || currentGroup?.type === 'activeWatchlist';
      if (currentGroup?.type === 'etf-view') {
        const etfGroups = etfViewConfig.groups.map((gr) => gr?.group);
        hasData = etfGroups?.every((g) => groupCollection?.[g]?.joined);
      }
      if (hasData) {
        setLoadingTab(false);
      }
    }
  };

  useEffect(() => {
    checkIfLoading();
  }, [currentTab, groupCollection]);

  useEffect(() => {
    checkIfLoading();
  }, [groupInView]);

  const handleChangeTab = (index) => {
    if (updatingDate) {
      return;
    }
    if (currentTab === index) return;
    dispatch({type: 'SET_TAB_IN_VIEW', payload: index});
    setLoadingTab(true);
  };

  const addETVViewToTabs = (existingTabs) => {
    const scannerId = params?.id;
    const scannerData = scannersData?.find((s) => s.slug === scannerId);
    const allGroupsFiltered = scannerData?.groups?.length ? scannerData?.groups?.filter((g) => !g?.hideTab) : [];
    let newGroups = [...allGroupsFiltered];
    if (existingTabs?.length) {
      newGroups = [...existingTabs];
    }
    if (!groupsWithWatchlist?.find((g) => g?.type === 'etf-view')) {
      if (scannerData?.SectorStrike?.locked) {
        newGroups = [
          ...newGroups,
          {type: 'etf-view', title: scannerData?.SectorStrike?.title ?? 'Sector Strike', locked: true},
        ];
      } else if (scannerData?.SectorStrike?.groups?.length) {
        newGroups = [...newGroups, {type: 'etf-view', title: scannerData?.SectorStrike?.title ?? 'Sector Strike'}];
      }
    }

    return newGroups;
  };

  useEffect(() => {
    const scannerId = params?.id;
    const scannerData = scannersData?.find((s) => s.slug === scannerId);
    if (anyAllowedWatchlist) {
      const newGroups = addETVViewToTabs(groupsWithWatchlist);
      const newGroupsTitles = newGroups.map((g) => g?.title ?? g?.group);
      const groupsWithWatchlistTitles = scannerData?.groups?.length
        ? scannerData?.groups?.map((g) => g?.title ?? g?.group)
        : [];
      if (newGroups && !isEqual(newGroupsTitles, groupsWithWatchlistTitles)) {
        setGroupsWithWatchlist(newGroups);
      }
      return;
    }
    const allowedWatchlist = scannersData?.find((s) => s.slug === params?.id)?.allowedWatchlist ?? false;
    if (anyAllowedWatchlist !== allowedWatchlist) {
      setAnyAllowedWatchlist(allowedWatchlist);
    }
    if (!allowedWatchlist) {
      if (!isEqual(groups, groupsWithWatchlist)) {
        const newGroups = addETVViewToTabs(groupsWithWatchlist);
        const newGroupsTitles = newGroups.map((g) => g?.title ?? g?.group);
        const groupsWithWatchlistTitles = scannerData?.groups?.length
          ? scannerData?.groups?.map((g) => g?.title ?? g?.group)
          : [];
        if (newGroups && !isEqual(newGroupsTitles, groupsWithWatchlistTitles)) {
          setGroupsWithWatchlist([...newGroups]);
        } else {
          setGroupsWithWatchlist(groups);
        }
      }

      return;
    }
    const allGroupsFiltered = scannerData?.groups?.length ? scannerData?.groups?.filter((g) => !g?.hideTab) : [];
    let newGroups = [...allGroupsFiltered];

    if (!groupsWithWatchlist?.find((g) => g?.type === 'etf-view')) {
      if (scannerData?.SectorStrike?.locked) {
        newGroups = [
          ...allGroupsFiltered,
          {type: 'etf-view', title: scannerData?.SectorStrike?.title ?? 'Sector Strike', locked: true},
        ];
      } else if (scannerData?.SectorStrike?.groups?.length) {
        newGroups = [
          ...allGroupsFiltered,
          {type: 'etf-view', title: scannerData?.SectorStrike?.title ?? 'Sector Strike'},
        ];
      }
    }

    if (groupsWithWatchlist?.length && !groupsWithWatchlist?.find((g) => g?.type === 'activeWatchlist')) {
      newGroups = [...newGroups, {type: 'activeWatchlist', title: 'Watchlist'}];
    }

    const newGroupsTitles = newGroups.map((g) => g?.title ?? g?.group);
    const groupsWithWatchlistTitles = scannerData?.groups?.length
      ? scannerData?.groups?.map((g) => g?.title ?? g?.group)
      : [];
    if (isEqual(newGroupsTitles, groupsWithWatchlistTitles)) {
      return;
    }
    setGroupsWithWatchlist(newGroups);
  }, [groups?.length]);

  return (
    <>
      {/* <ScannerHeader name={scannerTitle} /> */}
      {groupsWithWatchlist?.length === 1 ? (
        <Scanner
          group={groupCollection[groupsWithWatchlist[0]?.group] ?? null}
          multiScreen={Object.keys(groupCollection || {}).length > 1}
          optionsColumns={scannerConfig?.optionsColumns ?? {}}
          allowedWatchlist={scannerConfig?.allowedWatchlist ?? false}
          optionsAllowed={scannerConfig?.optionsAllowed ?? false}
          lockedColumns={scannerConfig?.lockedColumns ?? {}}
          useAI={scannerConfig?.useAI ?? false}
          displayChartDisabledMessage={scannerConfig?.displayChartDisabledMessage ?? true}
          groupName={groupsWithWatchlist[0]?.group}
          inView={inView}
          currentDate={currentDate}
          noDataMessage={scannerConfig?.noDataMessage ?? 'No Data Available'}
          displayHeaderInline
        />
      ) : (
        <Tabs
          onSelect={(index) => {
            handleChangeTab(index);
          }}
          selectedIndex={currentTab}
        >
          <TabList>
            {groupsWithWatchlist?.map((group) => {
              if (group?.type === 'activeWatchlist') {
                return <Tab key="watchlist-tab">Personal Watchlist</Tab>;
              }
              if (group?.type === 'etf-view') {
                return (
                  <Tab key="etf-tab" disabled={group?.locked}>
                    {group?.locked ? (
                      <button
                        type="button"
                        label="open options"
                        onClick={() => {
                          if (group.upcoming) {
                            if (!openUpcomingModal) return;
                            openUpcomingModal();
                            return;
                          }
                          if (!openLockedModal) return;
                          openLockedModal();
                        }}
                      >
                        {group?.title ?? group?.group} <span>&nbsp;</span>
                        <FontAwesomeIcon icon={faLock} />
                      </button>
                    ) : (
                      group?.title ?? group?.group
                    )}
                  </Tab>
                );
              }

              return (
                <Tab key={group?.title ?? group?.group} disabled={group?.locked}>
                  {group?.locked ? (
                    <button
                      type="button"
                      label="open options"
                      onClick={() => {
                        if (group.upcoming) {
                          if (!openUpcomingModal) return;
                          openUpcomingModal();
                          return;
                        }
                        if (!openLockedModal) return;
                        openLockedModal();
                      }}
                    >
                      {group?.title ?? group?.group} <span>&nbsp;</span>
                      <FontAwesomeIcon icon={faLock} />
                    </button>
                  ) : (
                    group?.title ?? group?.group
                  )}
                </Tab>
              );
            })}
          </TabList>
          {groupsWithWatchlist?.map((group) => {
            if (group?.type === 'activeWatchlist') {
              return (
                <TabPanel key="watchlist-scanner">
                  <Watchlist
                    optionsColumns={scannerConfig?.optionsColumns ?? {}}
                    optionsAllowed={scannerConfig?.optionsAllowed ?? false}
                    useAI={scannerConfig?.useAI ?? false}
                    lockedColumns={scannerConfig?.lockedColumns ?? {}}
                    allowCustomInputWatchlist={scannerConfig?.allowCustomInputWatchlist}
                    allowedChart={groupsWithWatchlist?.filter((g) => g?.allowedChart)?.length}
                  />
                </TabPanel>
              );
            }

            if (group?.type === 'etf-view') {
              return (
                <TabPanel key="etf-view">
                  {loadingTab ? (
                    <div className="w-full h-full min-h-screen flex items-center justify-center">
                      <div className="lds-dual-ring-main" />
                    </div>
                  ) : (
                    <ETFView
                      groups={etfViewConfig?.groups ?? []}
                      groupCollection={groupCollection}
                      layout={etfViewConfig?.layout ?? {}}
                      currentDate={currentDate}
                      optionsColumns={scannerConfig?.optionsColumns ?? {}}
                      optionsAllowed={scannerConfig?.optionsAllowed ?? false}
                      lockedColumns={scannerConfig?.lockedColumns ?? {}}
                    />
                  )}
                </TabPanel>
              );
            }

            return (
              <TabPanel key={group?.title ?? group?.group}>
                {loadingTab ? (
                  <div className="w-full h-full min-h-screen flex items-center justify-center">
                    <div className="lds-dual-ring-main" />
                  </div>
                ) : groupCollection?.[group.group] ? (
                  <Scanner
                    group={groupCollection[group.group] ?? null}
                    multiScreen={Object.keys(groupCollection || {}).length > 1}
                    optionsColumns={scannerConfig?.optionsColumns ?? {}}
                    allowedWatchlist={scannerConfig?.allowedWatchlist ?? false}
                    optionsAllowed={scannerConfig?.optionsAllowed ?? false}
                    lockedColumns={scannerConfig?.lockedColumns ?? {}}
                    useAI={scannerConfig?.useAI ?? false}
                    displayChartDisabledMessage={scannerConfig?.displayChartDisabledMessage ?? true}
                    groupName={group?.group}
                    inView={inView}
                    currentDate={currentDate}
                    noDataMessage={scannerConfig?.noDataMessage ?? 'No Data Available'}
                    displayHeaderInline={false}
                  />
                ) : null}
              </TabPanel>
            );
          })}
        </Tabs>
      )}
      {/* Locked Modal */}
      <Modal isOpen={showLockedModal} onRequestClose={closeLockedModal} style={customStylesLocked}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeLockedModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div className="upgrade-modal">
          <h1>
            To unlock this feature, reach out in the mod chat or{' '}
            <a
              href="https://s3.amazonaws.com/assets.monumenttradersalliance.com/schedule-a-call/dpsup/index.html"
              target="_blank"
              rel="noreferrer"
            >
              click here to schedule a call with our VIP Service Squad
            </a>
            .
          </h1>
          {/* <a
            className="click-here"
            href="https://s3.amazonaws.com/assets.monumenttradersalliance.com/schedule-a-call/dpsup/index.html"
            target="_blank"
            rel="noreferrer"
          >
            Click Here to Upgrade
          </a> */}
        </div>
      </Modal>
      {/* Upcoming Modal */}
      <Modal isOpen={showUpcomingModal} onRequestClose={closeUpcomingModal} style={customStylesUpcoming}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeUpcomingModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div className="upgrade-modal">
          <h1>Feature Coming Soon</h1>
        </div>
      </Modal>
      {/* Trading Chart Modal */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <TradingViewChart
          symbolToChart={symbolToChart}
          wsSocket={wsSocket}
          groupCollection={groupCollection}
          theme={theme}
          groupToChart={groupToChart}
          supportedResolutions={scannerConfig?.chartConfig?.supportedResolutions ?? []}
          timeFrames={scannerConfig?.chartConfig?.time_frames ?? []}
          scannerTitle={scannerConfig?.title ?? null}
        />
      </Modal>
      {/* Options Modal */}
      <Modal isOpen={showOptionsModal} onRequestClose={closeOptionsModal} style={customStylesOptions}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeOptionsModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <OptionView
            optionToView={optionToView}
            groupCollection={groupCollection}
            optionsLayout={scannerConfig?.optionsLayout ?? {}}
          />
        </div>
      </Modal>
      {/* AI Modal */}
      <Modal isOpen={showAIModal} onRequestClose={closeAIModal} style={customStylesAI}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeAIModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <AIView symbolForAI={symbolForAI} />
        </div>
      </Modal>
    </>
  );
};

const optionValuesSame = (scannersData, prev, next) => {
  const id = window.location.pathname.replace('/scanners/', '');
  const optionsLayout = scannersData?.find((s) => s.slug === id)?.optionsLayout ?? {};
  const optionsLayoutValues = Object.values(optionsLayout ?? {});
  if (!optionsLayoutValues?.length) {
    return true;
  }
  const {groupCollection: prevGroupCollection, optionToView: prevOptionToView} = prev ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {groupCollection: nextGroupCollection, optionToView: nextOptionToView} = next ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {group: prevGroup, symbol: prevSymbol} = prevOptionToView ?? {group: null, symbol: null};
  const {group: nextGroup, symbol: nextSymbol} = nextOptionToView ?? {group: null, symbol: null};
  const {data: prevData} = prevGroupCollection?.[prevGroup] ?? {data: null};
  const {data: nextData} = nextGroupCollection?.[nextGroup] ?? {data: null};
  if (!prevData && !nextData) {
    return true;
  }
  const prevSymbolData = prevData?.[prevSymbol];
  const nextSymbolData = nextData?.[nextSymbol];

  if (!isEqual(prevSymbolData, nextSymbolData)) {
    const areSame = optionsLayoutValues.every((l) => {
      const {keys} = l;
      return keys.every((k) => isEqual(prevSymbolData?.[k]?.value, nextSymbolData?.[k]?.value));
    });
    return areSame;
  }
  return true;
};

export default React.memo(TabView, (prev, next) => {
  if (prev?.showLockedModal && next?.showLockedModal) {
    return prev?.showLockedModal === next?.showLockedModal;
  }
  if (prev?.showUpcomingModal && next?.showUpcomingModal) {
    return prev?.showUpcomingModal === next?.showUpcomingModal;
  }
  if (prev?.showOptionsModal && next?.showOptionsModal) {
    return prev.optionToView === next.optionToView && optionValuesSame(next?.scannersData, prev, next);
  }
  if (prev?.modalIsOpen && next?.modalIsOpen) {
    return prev?.groupToChart === next?.groupToChart && prev?.symbolToChart === next?.symbolToChart;
  }

  const collectionSame = isEqual(prev?.groupCollection, next?.groupCollection);
  const groupsSame = isEqual(prev?.groups, next?.groups);
  const showOptionsModalSame = prev.showOptionsModal === next.showOptionsModal;
  const modalIsOpenSame = prev?.modalIsOpen === next?.modalIsOpen;
  const showLockedModalSame = prev?.showLockedModal === next?.showLockedModal;
  const showUpcomingModalSame = prev?.showUpcomingModal === next?.showUpcomingModal;
  const chartModalSame = prev?.groupToChart === next?.groupToChart && prev?.symbolToChart === next?.symbolToChart;
  const inViewSame = prev?.inView === next?.inView;

  return (
    collectionSame &&
    groupsSame &&
    showOptionsModalSame &&
    modalIsOpenSame &&
    showLockedModalSame &&
    showUpcomingModalSame &&
    chartModalSame &&
    inViewSame
  );
});
// export default TabView;
