import React, {useState, useEffect} from 'react';
import {isEqual} from 'lodash';
import moment from 'moment';

moment.locale();

const NumAbbr = require('number-abbreviate');

const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const formatNumber = new Intl.NumberFormat('en-US', {
  currency: 'USD',
});

const Title = ({layout, currentDate, optionData}) => {
  if (!layout) return null;
  const {replaceTitle, title} = layout;
  if (replaceTitle) {
    if (replaceTitle?.type === 'date') {
      if (currentDate) {
        const selectedToMomentDay = moment(currentDate, 'YYYY-MM-DD');
        return <h1>{selectedToMomentDay.format('ll')}</h1>;
      }
      return <h1>{moment().format('ll')}</h1>;
    }
    if (replaceTitle?.type === 'use-column') {
      const {column} = replaceTitle;
      return <h1>{optionData?.[column]?.value ?? title}</h1>;
    }
  }

  return <h1>{title}</h1>;
};

const formatValue = ({val, key, optionData, optionDataTypes, index = null, overrideDisplay = null}) => {
  const {overrides, prefix} = val;

  let value = optionData[key]?.value;
  let overrideStyle = null;
  let overrideLabelStyle = null;
  if (!value) {
    return {value: null, overrideStyle};
  }
  if (overrides) {
    overrides.forEach((o) => {
      const {
        key: overrideKey,
        value: compareValue,
        operator,
        overrideValue,
        overrideStyle: compareStyle,
        overrideLabelStyle: compareLabelStyle,
      } = o;
      const compareData = optionData[overrideKey]?.value;
      if (operator === 'eq' && compareValue === compareData) {
        value = overrideValue;
        overrideStyle = overrideStyle ?? compareStyle ?? null;
        overrideLabelStyle = overrideLabelStyle ?? compareLabelStyle ?? null;
      }
      if (operator === 'includes' && Array.isArray(compareValue) && compareValue.includes(compareData)) {
        value = overrideValue;
        overrideStyle = overrideStyle ?? compareStyle ?? null;
        overrideLabelStyle = overrideLabelStyle ?? compareLabelStyle ?? null;
      }
    });
  }
  if (prefix) {
    value = `${prefix}${value}`;
  }

  if (value) {
    const dataType = optionDataTypes.find((dt) => dt.key === key);
    if (!dataType) {
      return {value, overrideStyle, overrideLabelStyle};
    }
    const {type, display} = dataType;
    const displayType = overrideDisplay ?? display;
    if (type === 'array' && Array.isArray(value) && index !== null && value?.[index]) {
      value = value[index];
    }
    if ((type === 'number' || type === 'array') && !Number.isNaN(Number(value))) {
      if (displayType === 'money') {
        return {value: formatPrice.format(Number(value)), overrideStyle, overrideLabelStyle};
      }
      if (displayType === 'money-short') {
        return {value: `$${NumAbbr(Number(value), 2)}`.toUpperCase(), overrideStyle, overrideLabelStyle};
      }
      if (displayType === 'percent') {
        return {value: `${Number(value).toFixed(2)}%`, overrideStyle, overrideLabelStyle};
      }
      if (displayType === 'number-short') {
        return {value: `${NumAbbr(Number(value), 2)}`.toUpperCase(), overrideStyle, overrideLabelStyle};
      }

      if (displayType === 'number') {
        return {value: formatNumber.format(Number(value)), overrideStyle, overrideLabelStyle};
      }
      // if (display === '') {
      //   return
      // }
    } else if (type === 'array' && (Number.isNaN(value) || !value)) {
      return {value: '', overrideStyle, overrideLabelStyle};
    }
  }
  return {value, overrideStyle, overrideLabelStyle};
};

const OptionView = ({optionToView, groupCollection, optionsLayout, currentDate}) => {
  const [optionData, setOptionData] = useState(null);
  const [optionDataTypes, setOptionDataTypes] = useState(null);
  const [formattedWithLabelStyles, setFomattedWithLabelStyles] = useState(null);

  useEffect(() => {
    if (!optionToView || !groupCollection) {
      if (optionData) setOptionData(null);
      return;
    }
    if (optionToView) {
      const {group, symbol} = optionToView;
      const collectionData = groupCollection[group];
      if (collectionData && collectionData?.data && collectionData?.dataTypes) {
        if (collectionData?.data?.[symbol]) {
          setOptionData(collectionData.data?.[symbol]);
        }
        if (collectionData?.dataTypes) {
          setOptionDataTypes(collectionData.dataTypes);
        }
      } else if (optionData) {
        setOptionData(null);
      }
    }
  }, [optionToView, groupCollection]);

  useEffect(() => {
    if (!optionToView?.key || !optionData || !optionDataTypes) {
      return;
    }
    const formatted = Object.values(optionsLayout?.[optionToView.key]?.display ?? {}).map((val) => {
      const {keys, label, labelStyle, style, index, overrideDisplay} = val;
      const newVal = {
        label,
        keys,
        style,
        labelStyle,
      };
      const formattedKeys = keys.map((k, i) => {
        const {value, overrideStyle, overrideLabelStyle} = formatValue({
          val,
          key: k,
          optionData,
          optionDataTypes,
          index,
          overrideDisplay,
        });
        if (overrideLabelStyle) {
          newVal.labelStyle = overrideLabelStyle;
        }
        return {value, overrideStyle, key: k};
      });
      return {...newVal, formattedKeys};
    });
    if (!isEqual(formatted, formattedWithLabelStyles)) {
      setFomattedWithLabelStyles(formatted);
    }
  }, [optionData, optionDataTypes]);

  if (optionToView && !optionData && optionToView?.key) {
    return (
      <div>
        <div className="flex items-start justify-between flex-col gap-2">
          <div className="power-options">
            <h2 className="text-xl font-bold">{optionData?.Symbol?.value ?? optionToView?.symbol}</h2>
            <h1>{optionsLayout?.[optionToView.key].title}</h1>
            {optionsLayout?.[optionToView.key]?.secondaryTitle ? (
              <h3>{optionsLayout?.[optionToView.key]?.secondaryTitle}</h3>
            ) : null}
          </div>
        </div>
        <div>
          <div className="my-4 text-center">
            <div className="loader" />
          </div>
        </div>
      </div>
    );
  }

  return optionData && optionToView?.key && formattedWithLabelStyles?.length ? (
    <div>
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h2 className="text-[28px] -mb-1 font-bold">{optionData?.Symbol?.value}</h2>
          <Title layout={optionsLayout?.[optionToView?.key]} currentDate={currentDate} optionData={optionData} />
          {optionsLayout?.[optionToView.key]?.secondaryTitle ? (
            <h3>{optionsLayout?.[optionToView.key]?.secondaryTitle}</h3>
          ) : null}
        </div>
      </div>
      <div>
        <div className=" flow-root">
          <div className="-my-2 overflow-x-hidden ">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
                      <span />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold ">
                      <span />
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {optionToView?.key && formattedWithLabelStyles?.length
                    ? formattedWithLabelStyles.map((val) => {
                        const {formattedKeys, label, labelStyle, style} = val;
                        return (
                          <tr key={label}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                              <div className="option-wrap">
                                <div className="option-label" style={labelStyle ?? {}}>
                                  <span>{label}</span>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-pre-wrap px-3 py-4 text-sm ">
                              {formattedKeys.map((k, i) => {
                                const prevKey = i - 1 >= 0 ? formattedKeys[i - 1] : formattedKeys[0];
                                const {value, overrideStyle, key} = k;
                                // if (key.includes('Ty') && prevKey && !optionData[prevKey]?.value && !key.includes('Typ')) {
                                //   if (i === 0) {
                                //     return <span key={key}>No Power Option Play Available</span>;
                                //   }
                                //   return null;
                                // }
                                // if (key === '') {
                                //   console.log('value', value);
                                // }
                                if (!value) {
                                  return null;
                                }
                                if (value) {
                                  if (key.includes('Ex')) {
                                    return (
                                      <span key={key}>
                                        Exp: <span className="font-semibold">{value}</span> <br />
                                      </span>
                                    );
                                  }
                                  if (key.includes('Stk')) {
                                    return (
                                      <span key={key}>
                                        Strike: <span className="font-semibold">{value}</span>
                                      </span>
                                    );
                                  }
                                  if (key.includes('Ty') || key.includes('Typ')) {
                                    return (
                                      <span key={key}>
                                        <br />
                                        Type: <span className="font-semibold">{value}</span>
                                      </span>
                                    );
                                  }

                                  return (
                                    <span key={key}>
                                      {i === 0 || !optionData[prevKey]?.value ? null : <br />}
                                      <span className="font-semibold" style={overrideStyle ?? style ?? {}}>
                                        {value}
                                      </span>
                                    </span>
                                  );
                                }
                                return null;
                              })}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const optionValuesSame = (optionsLayout, prev, next) => {
  const optionsLayoutValues = Object.values(optionsLayout ?? {});
  if (!optionsLayoutValues?.length) {
    return true;
  }
  const {groupCollection: prevGroupCollection, optionToView: prevOptionToView} = prev ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {groupCollection: nextGroupCollection, optionToView: nextOptionToView} = next ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {group: prevGroup, symbol: prevSymbol} = prevOptionToView ?? {group: null, symbol: null};
  const {group: nextGroup, symbol: nextSymbol} = nextOptionToView ?? {group: null, symbol: null};
  const {data: prevData} = prevGroupCollection?.[prevGroup] ?? {data: null};
  const {data: nextData} = nextGroupCollection?.[nextGroup] ?? {data: null};
  if (!prevData && !nextData) {
    return true;
  }
  const prevSymbolData = prevData?.[prevSymbol];
  const nextSymbolData = nextData?.[nextSymbol];

  if (JSON.stringify(prevSymbolData) !== JSON.stringify(nextSymbolData)) {
    const areSame = optionsLayoutValues.every((l) => {
      const {keys} = l;
      return keys.every(
        (k) => JSON.stringify(prevSymbolData?.[k]?.value) === JSON.stringify(nextSymbolData?.[k]?.value),
      );
    });
    return areSame;
  }
  return true;
};

export default React.memo(OptionView, (prev, next) => {
  const optionToViewSame =
    prev.optionToView?.group === next.optionToView?.group && prev.optionToView?.symbol === next.optionToView?.symbol;
  const optionsLayoutSame = JSON.stringify(prev.optionsLayout) === JSON.stringify(next.optionsLayout);
  const showSecondaryHeaderSame = prev.showSecondaryHeader === next.showSecondaryHeader;
  const currentDateSame = prev.currentDate === next.currentDate;
  return (
    optionsLayoutSame &&
    optionToViewSame &&
    optionValuesSame(next?.optionsLayout, prev, next) &&
    showSecondaryHeaderSame &&
    currentDateSame
  );
});

// export default OptionView;
